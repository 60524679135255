import React from "react"
import TileList from "../components/tilelist"
import SubnavList from "../components/subnavList"

const PageContent = () => (
  <div className="contentArea">
    <h2>Committed Creator &amp; Educator </h2>
    <p>
      Delivering decisions and software built with care and intention for humans
      and their businesses.Worked on projects for phones, watches, websites, or
      anything with a screen.
    </p>
    <p>
      College educator spreading knowledge about producing the softest wares.
      Always ready to teach myself or others how to code, design, and create
    </p>
  </div>
)

const projectPage = () => {
  return (
    <div className="projects">
      <SubnavList />
      <div className="largeContent">
        <TileList />
      </div>
      <PageContent />
    </div>
  )
}

export default projectPage;